body {
    min-width: 320px;
    background: white;
}
.page-container {
    width: inherit;
    margin: 0 0;
    box-shadow: none;
}
.profile-picture {
    margin: 0 auto;
    display: block;
    float: none;
}
.map-image {
    float: none;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    height: inherit;
    display: block;
}
.videoplayer {
    width: 100%;
    max-width: 500px;
}
.call-out-box {
    display: none;
}
.photo {
    width: 241px;
    height: 364px;
    display: block;
    float: none;
    margin: 4px auto;
}
.not-mobile {
    display: none !important;
}
