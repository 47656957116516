.footer .social-media {
    display: none;
}
.footer .contact-info {
    float: none;
}
.footer .footer-menu {
    border-top: 1px solid #DEDEDE;
}
.footer .footer-menu ul {
    text-align: center;
}
.footer .footer-menu ul li {
    display: inline;
    float: none;
}
.footer .copyright {
    text-align: center;
}
