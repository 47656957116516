.header {
    background: none;
    width: auto;
    height: auto;
    border-bottom: none;
}
.header .mobile-image {
    display: block;
    text-align: center;
}
.header .mobile-image img {
    width: 100%;
    max-width: 360px;
    border: none;
}
.header-quote {
    display: none;
}
.header-menu {
    padding: 0;
    border-bottom: 1px solid #DEDEDE;
}
.header-menu ul {
    height: auto;
    background: white;
    text-align: center;
}
.header-menu ul li {
    display: inline-block;
    background: white;
    border-right: 1px solid #DEDEDE;
    float: none;
}
.header-menu ul li.desktop {
    display: none;
}
.header-menu ul li.mobile-last {
    border-right: none;
}
.header-menu ul li a {
    font-size: 12px;
    display: inline;
    padding: 4px 8px;
}
